import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

// ProtectedRoute component that protects routes
const ProtectedRoute = ({ component: Component }) => {
  const { authToken, loading } = useContext(AuthContext);

  // If loading, show a loading spinner
  if (loading) {
    return <div>Loading authentication...</div>; // Block UI until loading completes
  }

  // If no authToken, redirect to login
  if (!authToken) {
    return <Navigate to="/login" />;
  }

  // If authenticated, render the protected component
  return <Component />;
};

export default ProtectedRoute;
