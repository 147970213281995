import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// Create the Auth Context
export const AuthContext = createContext();

// AuthProvider component that wraps the app
export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(localStorage.getItem('authToken') || null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Function to login and store authToken & refreshToken
  const login = async (username, password) => {
    try {
      const response = await axios.post('/api/login', { username, password });
      const { authToken } = response.data;

      // Store the token in localStorage
      localStorage.setItem('authToken', authToken);
      setAuthToken(authToken);
    } catch (error) {
      console.error('Login failed', error);
    }
  };

  // Function to logout the user
  const logout = () => {
    localStorage.removeItem('authToken');
    setAuthToken(null);
    navigate('/login');
  };

  // Effect to check if token is available
  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      setAuthToken(token);
    }
    setLoading(false); // Stop loading after token check
  }, []);

  return (
    <AuthContext.Provider value={{ authToken, login, logout, loading }}>
      {/* Only render children if loading is false */}
      {!loading ? children : <div>Loading authentication...</div>}
    </AuthContext.Provider>
  );
};
