import React, { Suspense, useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ProtectedRoute from './auth/ProtectedRoute'; // Import ProtectedRoute
import { AuthProvider } from './auth/AuthContext'; // Import AuthProvider to manage auth state globally

// Lazy loading components
const LoginPage = React.lazy(() => import('./pages/LoginPage'));
const MainLayout = React.lazy(() => import('./layouts/MainLayout'));
const UsersPage = React.lazy(() => import('./pages/UsersPage'));
const ReportsPage = React.lazy(() => import('./pages/ReportsPage'));
const ProjectsPage = React.lazy(() => import('./pages/ProjectsPage'));
const ProjectDetailsPage = React.lazy(() => import('./pages/ProjectDetailsPage'));
const SettingsPage = React.lazy(() => import('./pages/SettingsPage'));
const FinancePage = React.lazy(() => import('./pages/FinancePage'));
const ServicesPage = React.lazy(() => import('./pages/ServicesPage'));
const DashboardPage = React.lazy(() => import('./pages/DashboardPage'));
const RolesPage = React.lazy(() => import('./pages/RolesPage'));
const RolesItemPage = React.lazy(() => import('./pages/RolesItemPage'));
const TemplatesPage = React.lazy(() => import('./pages/TemplatesPage'));
const UserDetailsPage = React.lazy(() => import('./pages/UserDetailsPage'));
const TemplateItemPage = React.lazy(() => import('./pages/TemplateItemPage'));
const TasksManagementPage = React.lazy(() => import('./pages/TasksManagementPage'));
const DocumentEditorPage = React.lazy(() => import('./pages/DocumentEditorPage'));
const PrivateChatPage = React.lazy(() => import('./pages/PrivateChatPage'));
const ArchivePage = React.lazy(() => import('./pages/ArchivePage'));
const ArchivedProjectsPage = React.lazy(() => import('./pages/ArchivedProjectsPage'));
const ArchivedReportsPage = React.lazy(() => import('./pages/ArchivedReportsPage'));
const ArchivedChatPage = React.lazy(() => import('./pages/ArchivedChatPage'));
const ArchivedTasksPage = React.lazy(() => import('./pages/ArchivedTasksPage'));
const ArchivedDocumentsPage = React.lazy(() => import('./pages/ArchivedDocumentsPage'));
const ManageTaskPage = React.lazy(() => import('./pages/ManageTaskPage'));
const NotificationsPage = React.lazy(() => import('./pages/NotificationsPage'));
const EmailPage = React.lazy(() => import('./pages/EmailPage'));

const ChatPage = React.lazy(() => import('./pages/ChatPage'));

const App = () => {
  // Memoizing the theme creation to avoid unnecessary recalculations
  const theme = useMemo(() => createTheme({
    direction: 'rtl',
    typography: {
      fontFamily: '"Zain", "Roboto", "Helvetica", "Arial", sans-serif',
    },
  }), []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AuthProvider> {/* AuthProvider moved inside Router */}
          <Suspense fallback={<div>[جاري التحميل]...</div>}>
            <Routes>
              {/* Public Routes */}
              <Route path="/login" element={<LoginPage />} />
              <Route path="/document-editor" element={<DocumentEditorPage />} />
              
              {/* Protected Routes */}
              <Route element={<MainLayout />}>
                <Route path="/dashboard" element={<ProtectedRoute component={DashboardPage} />} />
                <Route path="/chat" element={<ProtectedRoute component={ChatPage} />} />
                <Route path="/projects" element={<ProtectedRoute component={ProjectsPage} />} />
                <Route path="/projects/:id" element={<ProtectedRoute component={ProjectDetailsPage} />} />
                <Route path="/settings" element={<ProtectedRoute component={SettingsPage} />} />
                <Route path="/finance" element={<ProtectedRoute component={FinancePage} />} />
                <Route path="/services" element={<ProtectedRoute component={ServicesPage} />} />
                <Route path="/archive" element={<ProtectedRoute component={ArchivePage} />} />
                <Route path="/task/:taskId" element={<ProtectedRoute component={ManageTaskPage} />} />
                <Route path="/tasks/manage" element={<ProtectedRoute component={ManageTaskPage} />} />
                <Route path="/task-management" element={<ProtectedRoute component={TasksManagementPage} />} />
                <Route path="/roles" element={<ProtectedRoute component={RolesPage} />} />
                <Route path="/roles/:roleId" element={<ProtectedRoute component={RolesItemPage} />} />
                <Route path="/templates" element={<ProtectedRoute component={TemplatesPage} />} />
                <Route path="/users" element={<ProtectedRoute component={UsersPage} />} />
                <Route path="/users/:id" element={<ProtectedRoute component={UserDetailsPage} />} />
                <Route path="/templates/:id" element={<ProtectedRoute component={TemplateItemPage} />} />
                <Route path="/reports" element={<ProtectedRoute component={ReportsPage} />} />
                <Route path="/manager-chat/:userId" element={<ProtectedRoute component={PrivateChatPage} />} />
                <Route path="/archive/documents" element={<ProtectedRoute component={ArchivedDocumentsPage} />} />
                <Route path="/archive/projects" element={<ProtectedRoute component={ArchivedProjectsPage} />} />
                <Route path="/archive/tasks" element={<ProtectedRoute component={ArchivedTasksPage} />} />
                <Route path="/archive/chat" element={<ProtectedRoute component={ArchivedChatPage} />} />
                <Route path="/archive/reports" element={<ProtectedRoute component={ArchivedReportsPage} />} />
                <Route path="/notifications" element={<ProtectedRoute component={NotificationsPage} />} />
                <Route path="/emails" element={<ProtectedRoute component={EmailPage} />} />
              </Route>
              
              {/* Default Route */}
              <Route path="/" element={<LoginPage />} />
            </Routes>
          </Suspense>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
};

export default App;